//#region  Imports
import pic1 from "./../../assets/images/works/1.jpg";
import pic2 from "./../../assets/images/works/2.jpg";
import pic3 from "./../../assets/images/works/3.jpg";
import pic4 from "./../../assets/images/works/4.jpg";
import pic5 from "./../../assets/images/works/5.jpg";
import pic6 from "./../../assets/images/works/6.jpg";
import pic7 from "./../../assets/images/works/7.jpg";
import pic8 from "./../../assets/images/works/8.jpg";
import pic9 from "./../../assets/images/works/9.jpg";
import pic10 from "./../../assets/images/works/10.jpg";
import pic11 from "./../../assets/images/works/11.jpg";
import pic12 from "./../../assets/images/works/12.jpg";
import pic13 from "./../../assets/images/works/13.jpg";
import pic14 from "./../../assets/images/works/14.jpg";
import pic15 from "./../../assets/images/works/15.jpg";
import pic16 from "./../../assets/images/works/16.jpg";
import pic17 from "./../../assets/images/works/17.jpg";
import pic18 from "./../../assets/images/works/18.jpg";
import pic19 from "./../../assets/images/works/19.jpg";
import pic20 from "./../../assets/images/works/20.jpg";
import pic21 from "./../../assets/images/works/21.jpg";
import pic22 from "./../../assets/images/works/22.jpg";
import pic23 from "./../../assets/images/works/23.jpg";
import pic24 from "./../../assets/images/works/24.jpg";
import pic25 from "./../../assets/images/works/25.jpg";
import pic26 from "./../../assets/images/works/26.jpg";
import pic27 from "./../../assets/images/works/27.jpg";
import pic28 from "./../../assets/images/works/28.jpg";
import pic29 from "./../../assets/images/works/29.jpg";
import pic30 from "./../../assets/images/works/30.jpg";
import pic31 from "./../../assets/images/works/31.jpg";
import pic32 from "./../../assets/images/works/32.jpg";
import pic33 from "./../../assets/images/works/33.jpg";
import pic34 from "./../../assets/images/works/34.jpg";
import pic35 from "./../../assets/images/works/35.jpg";
import pic36 from "./../../assets/images/works/36.jpg";
import pic37 from "./../../assets/images/works/37.jpg";
import pic38 from "./../../assets/images/works/38.jpg";
import pic39 from "./../../assets/images/works/39.jpg";
import pic40 from "./../../assets/images/works/40.jpg";
import pic41 from "./../../assets/images/works/41.jpg";
import pic42 from "./../../assets/images/works/42.jpg";
import pic43 from "./../../assets/images/works/43.jpg";
import pic44 from "./../../assets/images/works/44.jpg";
import pic45 from "./../../assets/images/works/45.jpg";
import pic46 from "./../../assets/images/works/46.jpg";
import pic47 from "./../../assets/images/works/47.jpg";
import pic48 from "./../../assets/images/works/48.jpg";
import thumb1 from "./../../assets/images/works/thumbnails/1.jpg";
import thumb2 from "./../../assets/images/works/thumbnails/2.jpg";
import thumb3 from "./../../assets/images/works/thumbnails/3.jpg";
import thumb4 from "./../../assets/images/works/thumbnails/4.jpg";
import thumb5 from "./../../assets/images/works/thumbnails/5.jpg";
import thumb6 from "./../../assets/images/works/thumbnails/6.jpg";
import thumb7 from "./../../assets/images/works/thumbnails/7.jpg";
import thumb8 from "./../../assets/images/works/thumbnails/8.jpg";
import thumb9 from "./../../assets/images/works/thumbnails/9.jpg";
import thumb10 from "./../../assets/images/works/thumbnails/10.jpg";
import thumb11 from "./../../assets/images/works/thumbnails/11.jpg";
import thumb12 from "./../../assets/images/works/thumbnails/12.jpg";
import thumb13 from "./../../assets/images/works/thumbnails/13.jpg";
import thumb14 from "./../../assets/images/works/thumbnails/14.jpg";
import thumb15 from "./../../assets/images/works/thumbnails/15.jpg";
import thumb16 from "./../../assets/images/works/thumbnails/16.jpg";
import thumb17 from "./../../assets/images/works/thumbnails/17.jpg";
import thumb18 from "./../../assets/images/works/thumbnails/18.jpg";
import thumb19 from "./../../assets/images/works/thumbnails/19.jpg";
import thumb20 from "./../../assets/images/works/thumbnails/20.jpg";
import thumb21 from "./../../assets/images/works/thumbnails/21.jpg";
import thumb22 from "./../../assets/images/works/thumbnails/22.jpg";
import thumb23 from "./../../assets/images/works/thumbnails/23.jpg";
import thumb24 from "./../../assets/images/works/thumbnails/24.jpg";
import thumb25 from "./../../assets/images/works/thumbnails/25.jpg";
import thumb26 from "./../../assets/images/works/thumbnails/26.jpg";
import thumb27 from "./../../assets/images/works/thumbnails/27.jpg";
import thumb28 from "./../../assets/images/works/thumbnails/28.jpg";
import thumb29 from "./../../assets/images/works/thumbnails/29.jpg";
import thumb30 from "./../../assets/images/works/thumbnails/30.jpg";
import thumb31 from "./../../assets/images/works/thumbnails/31.jpg";
import thumb32 from "./../../assets/images/works/thumbnails/32.jpg";
import thumb33 from "./../../assets/images/works/thumbnails/33.jpg";
import thumb34 from "./../../assets/images/works/thumbnails/34.jpg";
import thumb35 from "./../../assets/images/works/thumbnails/35.jpg";
import thumb36 from "./../../assets/images/works/thumbnails/36.jpg";
import thumb37 from "./../../assets/images/works/thumbnails/37.jpg";
import thumb38 from "./../../assets/images/works/thumbnails/38.jpg";
import thumb39 from "./../../assets/images/works/thumbnails/39.jpg";
import thumb40 from "./../../assets/images/works/thumbnails/40.jpg";
import thumb41 from "./../../assets/images/works/thumbnails/41.jpg";
import thumb42 from "./../../assets/images/works/thumbnails/42.jpg";
import thumb43 from "./../../assets/images/works/thumbnails/43.jpg";
import thumb44 from "./../../assets/images/works/thumbnails/44.jpg";
import thumb45 from "./../../assets/images/works/thumbnails/45.jpg";
import thumb46 from "./../../assets/images/works/thumbnails/46.jpg";
import thumb47 from "./../../assets/images/works/thumbnails/47.jpg";
import thumb48 from "./../../assets/images/works/thumbnails/48.jpg";
//#endregion
export const Pics: any[] = [
  {
    src: pic47,
    key: pic47,
    width: 4,
    height: 5,
  },
  {
    src: pic46,
    key: pic46,
    width: 4,
    height: 5,
  },
  {
    src: pic48,
    key: pic48,
    width: 4,
    height: 5,
  },
  {
    src: pic1,
    key: pic1,
    width: 4,
    height: 5,
  },
  {
    src: pic2,
    key: pic2,
    width: 4,
    height: 5,
  },
  {
    src: pic3,
    key: pic3,
    width: 4,
    height: 5,
  },
  {
    src: pic4,
    key: pic4,
    width: 4,
    height: 5,
  },
  {
    src: pic5,
    key: pic5,
    width: 4,
    height: 5,
  },
  {
    src: pic6,
    key: pic6,
    width: 4,
    height: 5,
  },
  {
    src: pic7,
    key: pic7,
    width: 4,
    height: 5,
  },
  {
    src: pic8,
    key: pic8,
    width: 4,
    height: 5,
  },
  {
    src: pic9,
    key: pic9,
    width: 4,
    height: 5,
  },
  {
    src: pic10,
    key: pic10,
    width: 4,
    height: 5,
  },
  {
    src: pic11,
    key: pic11,
    width: 4,
    height: 5,
  },
  {
    src: pic12,
    key: pic12,
    width: 4,
    height: 5,
  },
  {
    src: pic13,
    key: pic13,
    width: 4,
    height: 5,
  },
  {
    src: pic14,
    key: pic14,
    width: 4,
    height: 5,
  },
  {
    src: pic15,
    key: pic15,
    width: 4,
    height: 5,
  },
  {
    src: pic16,
    key: pic16,
    width: 4,
    height: 5,
  },
  {
    src: pic17,
    key: pic17,
    width: 4,
    height: 5,
  },
  {
    src: pic18,
    key: pic18,
    width: 4,
    height: 5,
  },
  {
    src: pic19,
    key: pic19,
    width: 4,
    height: 5,
  },
  {
    src: pic20,
    key: pic20,
    width: 4,
    height: 5,
  },
  {
    src: pic21,
    key: pic21,
    width: 4,
    height: 5,
  },
  {
    src: pic22,
    key: pic22,
    width: 4,
    height: 5,
  },
  {
    src: pic23,
    key: pic22,
    width: 4,
    height: 5,
  },
  {
    src: pic24,
    key: pic24,
    width: 4,
    height: 5,
  },
  {
    src: pic25,
    key: pic25,
    width: 4,
    height: 5,
  },
  {
    src: pic26,
    key: pic26,
    width: 4,
    height: 5,
  },
  {
    src: pic27,
    key: pic27,
    width: 4,
    height: 5,
  },
  {
    src: pic28,
    key: pic28,
    width: 4,
    height: 5,
  },
  {
    src: pic29,
    key: pic29,
    width: 4,
    height: 5,
  },
  {
    src: pic30,
    key: pic30,
    width: 4,
    height: 5,
  },
  {
    src: pic31,
    key: pic31,
    width: 4,
    height: 5,
  },
  {
    src: pic32,
    key: pic32,
    width: 4,
    height: 5,
  },
  {
    src: pic33,
    key: pic33,
    width: 4,
    height: 5,
  },
  {
    src: pic34,
    key: pic34,
    width: 4,
    height: 5,
  },
  {
    src: pic35,
    key: pic35,
    width: 4,
    height: 5,
  },
  {
    src: pic36,
    key: pic36,
    width: 4,
    height: 5,
  },
  {
    src: pic37,
    key: pic37,
    width: 4,
    height: 5,
  },
  {
    src: pic38,
    key: pic38,
    width: 4,
    height: 5,
  },
  {
    src: pic39,
    key: pic34,
    width: 4,
    height: 5,
  },
  {
    src: pic40,
    key: pic40,
    width: 4,
    height: 5,
  },
  {
    src: pic41,
    key: pic41,
    width: 4,
    height: 5,
  },
  {
    src: pic42,
    key: pic42,
    width: 4,
    height: 5,
  },
  {
    src: pic43,
    key: pic43,
    width: 4,
    height: 5,
  },
  {
    src: pic44,
    key: pic44,
    width: 4,
    height: 5,
  },
  {
    src: pic45,
    key: pic45,
    width: 4,
    height: 5,
  },
];

export const Thumbnails: any[] = [
  {
    src: thumb47,
    key: thumb47,
    width: 4,
    height: 5,
  },
  {
    src: thumb46,
    key: thumb46,
    width: 4,
    height: 5,
  },
  {
    src: thumb48,
    key: thumb48,
    width: 4,
    height: 5,
  },
  {
    src: thumb1,
    key: thumb1,
    width: 4,
    height: 5,
  },
  {
    src: thumb2,
    key: thumb2,
    width: 4,
    height: 5,
  },
  {
    src: thumb3,
    key: thumb3,
    width: 4,
    height: 5,
  },
  {
    src: thumb4,
    key: thumb4,
    width: 4,
    height: 5,
  },
  {
    src: thumb5,
    key: thumb5,
    width: 4,
    height: 5,
  },
  {
    src: thumb6,
    key: thumb6,
    width: 4,
    height: 5,
  },
  {
    src: thumb7,
    key: thumb7,
    width: 4,
    height: 5,
  },
  {
    src: thumb8,
    key: thumb8,
    width: 4,
    height: 5,
  },
  {
    src: thumb9,
    key: thumb9,
    width: 4,
    height: 5,
  },
  {
    src: thumb10,
    key: thumb10,
    width: 4,
    height: 5,
  },
  {
    src: thumb11,
    key: thumb11,
    width: 4,
    height: 5,
  },
  {
    src: thumb12,
    key: thumb12,
    width: 4,
    height: 5,
  },
  {
    src: thumb13,
    key: thumb13,
    width: 4,
    height: 5,
  },
  {
    src: thumb14,
    key: thumb14,
    width: 4,
    height: 5,
  },
  {
    src: thumb15,
    key: thumb15,
    width: 4,
    height: 5,
  },
  {
    src: thumb16,
    key: thumb16,
    width: 4,
    height: 5,
  },
  {
    src: thumb17,
    key: thumb17,
    width: 4,
    height: 5,
  },
  {
    src: thumb18,
    key: thumb18,
    width: 4,
    height: 5,
  },
  {
    src: thumb19,
    key: thumb19,
    width: 4,
    height: 5,
  },
  {
    src: thumb20,
    key: thumb20,
    width: 4,
    height: 5,
  },
  {
    src: thumb21,
    key: thumb21,
    width: 4,
    height: 5,
  },
  {
    src: thumb22,
    key: thumb22,
    width: 4,
    height: 5,
  },
  {
    src: thumb23,
    key: thumb23,
    width: 4,
    height: 5,
  },
  {
    src: thumb24,
    key: thumb24,
    width: 4,
    height: 5,
  },
  {
    src: thumb25,
    key: thumb25,
    width: 4,
    height: 5,
  },
  {
    src: thumb26,
    key: thumb26,
    width: 4,
    height: 5,
  },
  {
    src: thumb27,
    key: thumb27,
    width: 4,
    height: 5,
  },
  {
    src: thumb28,
    key: thumb28,
    width: 4,
    height: 5,
  },
  {
    src: thumb29,
    key: thumb29,
    width: 4,
    height: 5,
  },
  {
    src: thumb30,
    key: thumb30,
    width: 4,
    height: 5,
  },
  {
    src: thumb31,
    key: thumb31,
    width: 4,
    height: 5,
  },
  {
    src: thumb32,
    key: thumb32,
    width: 4,
    height: 5,
  },
  {
    src: thumb33,
    key: thumb33,
    width: 4,
    height: 5,
  },
  {
    src: thumb34,
    key: thumb34,
    width: 4,
    height: 5,
  },
  {
    src: thumb35,
    key: thumb35,
    width: 4,
    height: 5,
  },
  {
    src: thumb36,
    key: thumb36,
    width: 4,
    height: 5,
  },
  {
    src: thumb37,
    key: thumb37,
    width: 4,
    height: 5,
  },
  {
    src: thumb38,
    key: thumb38,
    width: 4,
    height: 5,
  },
  {
    src: thumb39,
    key: thumb39,
    width: 4,
    height: 5,
  },
  {
    src: thumb40,
    key: thumb40,
    width: 4,
    height: 5,
  },
  {
    src: thumb41,
    key: thumb41,
    width: 4,
    height: 5,
  },
  {
    src: thumb42,
    key: thumb42,
    width: 4,
    height: 5,
  },
  {
    src: thumb43,
    key: thumb43,
    width: 4,
    height: 5,
  },
  {
    src: thumb44,
    key: thumb44,
    width: 4,
    height: 5,
  },
  {
    src: thumb45,
    key: thumb45,
    width: 4,
    height: 5,
  },
];
