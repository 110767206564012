import pic1 from './../../assets/images/services/IMG_4468.jpg';
import pic2 from './../../assets/images/services/IMG_4470.jpg';
import pic3 from './../../assets/images/services/IMG_4471.jpg';
import pic4 from './../../assets/images/services/IMG_4472.jpg';

import thumb1 from './../../assets/images/services/thumbnails/IMG_4468.jpg';
import thumb2 from './../../assets/images/services/thumbnails/IMG_4470.jpg';
import thumb3 from './../../assets/images/services/thumbnails/IMG_4471.jpg';
import thumb4 from './../../assets/images/services/thumbnails/IMG_4472.jpg';

export const Photos: any[] =
    [{
        src: pic1,
        key: pic1,
        width: 4,
        height: 5
    },
    {
        src: pic2,
        key: pic2,
        width: 4,
        height: 5
    },
    {
        src: pic3,
        key: pic3,
        width: 4,
        height: 5
    },
    {
        src: pic4,
        key: pic4,
        width: 4,
        height: 5
    }];


export const Thumbnails: any[] =
    [{
        src: thumb1,
        key: thumb1,
        width: 4,
        height: 5
    },
    {
        src: thumb2,
        key: thumb2,
        width: 4,
        height: 5
    },
    {
        src: thumb3,
        key: thumb3,
        width: 4,
        height: 5
    },
    {
        src: thumb4,
        key: thumb4,
        width: 4,
        height: 5
    }];