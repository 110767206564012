import React from "react";
import "./Videos.css";
import { Link } from "react-router-dom";

export interface IVideosProps {
  youtubeId: string;
}

export interface IVideosState { }

export class Videos extends React.Component<IVideosProps, IVideosState> {
  constructor(props: IVideosProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div>
        <div className="videos-container">

        <Link className="videos-item" to="player?videoId=FjRbRC4wQnU">
            <img
              alt="Christmas Vibes in Milan // BMPCC4K + Tamron 70-200mm f:2 8 G2 + Viltrox EF M2"
              src={require("./../../assets/images/videos/video19.jpeg")?.default}
            />
          </Link>

        <Link className="videos-item" to="player?videoId=v-S3IoG8ma0">
            <img
              alt="IL KILLER È MORTO - White Noise: voci dal carcere | Ep.01 (Explicit)"
              src={require("./../../assets/images/videos/video18.png")?.default}
            />
          </Link>

        <Link className="videos-item" to="player?videoId=SE1fXxCbUBc">
            <img
              alt="Learn to live in the forest - Valere kissi colorist"
              src={require("./../../assets/images/videos/video17.jpeg")?.default}
            />
          </Link>

        <Link className="videos-item" to="player?videoId=ba1GFeMIED8">
            <img
              alt="Oktoberfest Munich 2022 // BMPCC 4K + Sigma 18-35mm f/1.8"
              src={require("./../../assets/images/videos/video16.png")?.default}
            />
          </Link>

        <Link className="videos-item" to="player?videoId=8qUuPvi3DxA">
            <img
              alt="Milena Galasso - Summerstorm (Official Video)"
              src={require("./../../assets/images/videos/video15.png")?.default}
            />
          </Link>

          <Link className="videos-item" to="player?videoId=bnGwFpa5YWA">
            <img
              alt="Cinematic Travel Film / Mendrisio (Shot on BMPCC4K) by Valere Kissi"
              src={require("./../../assets/images/videos/video14.png")?.default}
            />
          </Link>

          <Link className="videos-item" to="player?videoId=aWtVoIg2w5o">
            <img
              alt="Ermenegildo Zegna XXX Summer 2022 Fashion Show by Valere Kissi"
              src={require("./../../assets/images/videos/video13.png")?.default}
            />
          </Link>
          
          <Link className="videos-item" to="player?videoId=a88lBmD1V7o">
            <img
              alt="Emilio & Tania // Engagement Video // BMPCC 4K + CANON FD 24mm f/2.8 + DJI RS2 by Valere Kissi"
              src={require("./../../assets/images/videos/video12.png")?.default}
            />
          </Link>
          
          <Link className="videos-item" to="player?videoId=6XnaaHfTHfw">
            <img
              alt="Baye Star - Baby (Official Video) by Valere Kissi"
              src={require("./../../assets/images/videos/video11-1.png")?.default}
            />
          </Link>

          <Link className="videos-item" to="player?videoId=RlcSRlIhqts">
            <img
              alt="PAOLA // BMPCC 4K + Sigma 18-35mm f/1.8 + DJI RS2 by Valere Kissi"
              src={require("./../../assets/images/videos/video10-1.png")?.default}
            />
          </Link>

          <Link className="videos-item" to="player?videoId=Tml5U-zWpxw">
            <img
              alt="Untitled - Shot on Blackmagic Pocket Cinema Camera 4K by Valere Kissi"
              src={require("./../../assets/images/videos/video8.png")?.default}
            />
          </Link>

          <Link className="videos-item" to="player?videoId=JDZHce56XjI">
            <img
              alt="Trevis Porter - FVCK LOVE (Prod. Eem Triplin x PRODBYKXVI) [Official Video] by Valere Kissi"
              src={require("./../../assets/images/videos/video2.png")?.default}
            />
          </Link>

          <Link className="videos-item" to="player?videoId=9xB3hoqZrW4">
            <img
              alt="The Break UP // Short film by Valere Kissi"
              src={require("./../../assets/images/videos/video7.png")?.default}
            />
          </Link>

          <Link className="videos-item" to="player?videoId=ExhtsEZgFXI">
            <img
              alt="AXEL MERRYL VIVIANE Parody Viviane by Prince Aime by Valere Kissi"
              src={require("./../../assets/images/videos/video3.png")?.default}
            />
          </Link>

          <Link className="videos-item" to="player?videoId=byb0UmGsZN8">
            <img
              alt="GINEVRA: BMPCC 4K - SIGMA 17-70mm FASHION VIDEO by Valere Kissi"
              src={require("./../../assets/images/videos/video9.png")?.default}
            />
          </Link>

          <Link className="videos-item" to="player?videoId=gAHgjcxVxXM">
            <img
              alt="CANON M50 100FPS B ROLL VIDEO by Valere Kissi"
              src={require("./../../assets/images/videos/video6.png")?.default}
            />
          </Link>

          <Link className="videos-item" to="player?videoId=R7lVdnZofRg">
            <img
              alt="JENNY: BMPCC 4K - SIGMA 18-35mm NIGHT VIDEO TEST by Valere Kissi"
              src={require("./../../assets/images/videos/video4.png")?.default}
            />
          </Link>

          <Link className="videos-item" to="player?videoId=Tukjxfl9JCU">
            <img
              alt="Presentazione REHOBOTH ONLUS by Valere Kissi"
              src={require("./../../assets/images/videos/video1.png")?.default}
            />
          </Link>

          <Link className="videos-item" to="player?videoId=dfbzq54B_og">
            <img
              alt="Venezia - Carnevale 2019 - Venice Carnival 2019 - Carnaval de Venise 2019 by Valere Kissi"
              src={require("./../../assets/images/videos/video5.png")?.default}
            />
          </Link>
        
        </div>
      </div>
    );
  }
}
